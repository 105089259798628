body {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-attachment: fixed;
    background-color: #FFFEED;;
}

.italics {
    font-style: italic;
    font-family: 'Lora';
    color: rgb(122,128,125);
    font-size: 20px;
}

ul{
    list-style-type: none;
    padding:0;
    overflow: hidden;
    width: 50%;
    margin: auto;
}

li {
    float:left;
    width: 33.33%;
    font-family: 'Lora', serif;
}
h2 a {
    display: block;
    color: rgb(122,128,125);
    text-align: center;
    padding: 0px 15px;
    font-size: 26px;
    letter-spacing: 5px;
}


ul li a {
    display: block;
    color: rgb(122,128,125);
    text-align: center;
    padding: 0px 15px;
    border-bottom : 4px solid #ffffff00;
    border-radius: 5px;
    font-size: 26px;
    transition: border-bottom 0.25s, color 0.25s;
    text-decoration: none;
    letter-spacing: 5px;
}

ul li a:hover {
    border-bottom: 4px solid rgb(19, 117, 55);
    color:rgb(19, 117, 55);
}

p {
    font-size: 25px;
    letter-spacing: normal;
    line-height: 1.6;
    color: rgb(122,128,125);
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

h1,
.date {
    font-size: 34px;
    font-weight: 400;
    color: rgb(122,128,125);
    letter-spacing: 5px;
    text-align: center;
    font-family: 'Lora';
    margin: 10px;
}

.date{
    letter-spacing: 10px;
}

h2,
.contact-header,
.contact-detail {
    font-size: 20px;
    font-weight: 700;
    color: rgb(122,128,125);
    letter-spacing: 10px;
    text-align: center;
    font-family: 'Lora';
    margin: 10px;
}

h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgb(122,128,125);
    letter-spacing: 3px;
    text-align: center;
    font-family: 'Lora';
    margin: 10px;
}

h4 {
    color:rgb(122,128,125);
    margin:auto;
    width: 50%;
    text-align:center;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Lora';
}

.important {
    font-weight: 700;
}

.spaced {
    margin-top: 50px;
    margin-bottom: 25px;
}

.centered {
    margin:auto;
    width: 50%;
    text-align:center;
    text-decoration: none;
}

.third {
    margin:auto;
    width: 33%;
    text-align:center;
    text-decoration: none;
}

.nav-bar{
    margin: auto;
    width: 100%;
    padding: 10px;
}

.more-info-link{
    margin-top: 25px;
}

.link-info {
    color:rgb(122,128,125);
    border-bottom: 2px solid rgba(122,128,125,0);;
    border-radius: 5px;
    text-decoration: none;
    transition: border-bottom 0.25s, font-size 0.25s;
}

.link-info:hover {
    border-bottom: 2px solid rgb(19, 117, 55);
}

.banner{
    margin-top: 20px;
    margin-bottom: 50px;
}

.index{
    margin-top: 50px;
    margin-bottom: 30px;
}

.main-page{
    max-width: 66%;
    margin:auto;
    width: 66%;
}

.main-Image{
    width: 100%;
}

.shadow-Box{
    box-shadow: 0 1px 5px rgba(0,0,0,0.25);
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.divider-Image{
    height: 250px;
}

.divider-line-long{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 50px;
    border-bottom: 4px solid rgb(19, 117, 55);
}

.divider-line-short{
    width: 66%;
    margin-top: 25px;
    margin-bottom: 50px;
    border-bottom: 2px solid rgb(19, 117, 55);
}

.content{
    border-radius: 5px;
    padding: 20px;
    max-width: 750px;
    margin: auto;
}

.mapBox{
    width: 85%;
}

.rsvp{
    width: 85%;
    margin: auto;
}



.icon-large{
    width: 100px;
}

.icon{
    width: 64px;
}

.icon-small{
    width: 32px;
}

.icon-registry{
    width: 100%;
}

.polaroid{
    width: 100%;
}

.flex-grid{
    display: flex;
    justify-content: space-between;
    margin:5px;
}

.col{
    flex: 1;
    width: 30%;
    margin: 10px;
}

.mobile{
    display: none;
}

.rsvp{
    width:100%;
    height: 100%;
}

.contact-header,
.contact-detail{
    letter-spacing: 2px;
    margin: 10px;
    float: left;
}

.contact-detail{
    font-weight: 400;
}

.annoucement{
    font-size: 26px;
    margin: 10, 0;
    color: #fffffa;
    vertical-align: middle;
    background-color: orangered;
}

.smallMap{
    display:none;
}
